@font-face {
  font-family: Poppins;
  src: url(../assets/fonts/Poppins-Medium.ttf);
}

html {
  --text-color: #222222;
  --second-text-color: #333333;
  --background-color: #eeeeee;
  --second-background-color: #bbbbbb;
  --secondary-color: #8fc9ff;
  --text-color-50: #2222225f;
  --preloader-background: #ffffff;

  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Poppins;
}

html {
  background: var(--background-color);
  scroll-padding-top: 80px;
}
