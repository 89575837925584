.about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding: 50px calc(20px + 5vw);
  background: var(--text-color);
}

.about .left-part {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
  height: 440px;
}

.about .left-part img[alt="about"] {
  position: relative;
  width: calc(120px + 5vw);
  aspect-ratio: 0.55;
  object-fit: cover;
  border-radius: 10px;
}

.about .left-part img[alt="about"]:nth-child(2) {
  align-self: flex-end;
}

.about .right-part h1 {
  font-size: 30px;
  font-weight: 100;
  color: var(--background-color);
}

.about .right-part h1 span {
  font-size: 40px;
  position: relative;
  color: var(--secondary-color);
  z-index: 2;
}

.about .right-part p.p_1 {
  color: var(--second-background-color);
  margin: 15px 0;
}
.about .right-part p.p_2 {
  color: var(--second-background-color);
  font-size: 0.9em;
}

@media (max-width: 900px) {
  .about {
    justify-content: center;
  }

  .about .left-part {
    display: none;
  }
}
