.hero {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding: 0 calc(20px + 5vw);
  overflow: hidden;
}

.hero .hero-content h1 {
  color: transparent;
  -webkit-text-stroke: var(--text-color) 1px;
  font-weight: 600;
  font-size: calc(50px + 4vw);
  line-height: 0.8em;
}

.hero .hero-content h3 {
  font-weight: 500;
  font-size: calc(20px + 2vw);
  line-height: 1em;
  color: var(--text-color);
}

.hero .hero-content p {
  margin: 15px 0;
  font-size: 15px;
  line-height: 1em;
  color: var(--text-color);
}

.hero .hero-content .hero-links {
  display: flex;
  align-items: center;
  justify-content: baseline;
  gap: 10px;
}

.hero .hero-content .hero-links a {
  height: 50px;
  padding: 0 20px;
  background: #000;
  border-radius: 10px;

  display: grid;
  place-content: center;
  text-decoration: none;
  transition: 0.3s;
}

.hero .hero-content .hero-links a.project {
  color: var(--background-color);
  background: var(--text-color);
  font-size: 15px;
}

.hero .hero-content .hero-links a:hover {
  translate: 0 -5px;
}

.hero .hero-content .hero-links a.linkedin {
  background: none;
  border: 2px solid var(--text-color);
  color: var(--text-color);
}

div.img-holder {
  width: calc(100px + 20vw);
}

.hero img[alt="hero-img"] {
  position: absolute;
  bottom: -30px;
  right: -0;
  width: calc(120px + 21vw);
  aspect-ratio: 0.7;
  object-fit: cover;
  border-radius: 30px 0 0 0;

  box-shadow: -20px 20px 0 var(--secondary-color);
}

.hero div.scroll-sign {
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.hero div.scroll-sign p {
  font-weight: 900;
  color: var(--text-color);
  font-size: 15px;
}

.hero div.scroll-sign div {
  position: relative;
  width: 25px;
  height: 50px;
  border: 2px solid var(--text-color);
  border-radius: 100px;

  display: flex;
  justify-content: center;
}

.hero div.scroll-sign div::before {
  content: "";
  position: absolute;
  bottom: 2px;
  width: 17px;
  height: 17px;
  background: var(--text-color);
  border-radius: 50%;

  animation: scroll 1s infinite;
}

@keyframes scroll {
  to {
    bottom: 100%;
    transform: translateY(50%);
    opacity: 0;
  }
}

@media (max-width: 900px) {
  div.img-holder,
  .hero img[alt="hero-img"] {
    display: none;
  }

  .hero .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hero .hero-content > * {
    text-align: center;
  }
}
