.process {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;

  padding: 50px calc(20px + 5vw);
}

.process > h1 {
  color: var(--text-color);
  font-size: 30px;
}
.process > p {
  font-size: 18px;
  color: var(--second-text-color);
}

.process-body {
  margin-top: 30px;
}

.process-body .process-item {
  display: flex;
  gap: 30px;
}

.process-body .process-item .process-line {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.process-body .process-item .process-line .line {
  height: 100%;
  width: 3px;
  background: var(--secondary-color);
}

.process-body .process-item .process-line .line:first-child {
  -webkit-mask-image: linear-gradient(to bottom, white, transparent);
  mask-image: linear-gradient(to bottom, white, transparent);
}
.process-body .process-item .process-line .line:last-child {
  -webkit-mask-image: linear-gradient(to top, white, transparent);
  mask-image: linear-gradient(to top, white, transparent);
}
.process-body .process-item:first-child .process-line .line:first-child {
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent,
    white,
    transparent
  );
  mask-image: linear-gradient(to bottom, transparent, white, transparent);
}
.process-body .process-item:last-child .process-line .line:last-child {
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent,
    white,
    transparent
  );
  mask-image: linear-gradient(to bottom, transparent, white, transparent);
}

.process-body .process-item .process-line img {
  width: 40px;
}

.process-body .process-item .process-info {
  padding: 30px 0;
}

.process-body .process-item .process-info h2 {
  color: var(--text-color);
}

.process-body .process-item .process-info ul {
  margin-left: 40px;
  color: var(--second-text-color);
}

.process .projet-button {
  position: sticky;
  top: 35vh;
  text-decoration: none;
  display: grid;
  place-content: center;
  width: 200px;
  height: 200px;
  background: var(--text-color);
  border-radius: 50%;

  font-size: 20px;
  color: var(--background-color);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

  margin: auto;
}