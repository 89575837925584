.collaboraters {
  padding: 50px calc(20px + 5vw);
}

.collaboraters > p {
  text-align: center;
  color: var(--second-text-color);
  font-size: 18px;
}
.collaboraters > h1 {
  line-height: 1em;
  text-align: center;
  color: var(--text-color);
  font-size: 35px;
}
.collaboraters > h1 span {
  font-size: 40px;
  color: var(--secondary-color);
}

.logos {
  position: relative;
  overflow: hidden;
  margin: 60px 0;
  white-space: nowrap;

  -webkit-mask-image: linear-gradient(
    to right,
    transparent,
    white,
    white,
    white,
    transparent
  );
  mask-image: linear-gradient(
    to right,
    transparent,
    white,
    white,
    white,
    transparent
  );
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 20s slide infinite linear;
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
