.services {
  padding: 50px calc(20px + 5vw);
}

.services .services-desc h3 {
  color: var(--second-text-color);
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 10px;
}
.services .services-desc h1 {
  color: var(--text-color);
  font-size: 30px;
  line-height: 32px;
}

.services .services-cards {
  width: 100%;
  margin-top: 20px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.services .services-cards .card {
  overflow: hidden;
  width: min-content;
  height: calc(360px - 10vw);
  position: relative;
  border-radius: 20px;
  padding: 50px 15px;
  border: 2px solid var(--second-text-color);
  background: linear-gradient(to top, #000000a8, #000000), var(--src);
  background-position: center;
  background-size: cover;
  transition: 0.3s;
  flex-grow: 1;
}

.services .services-cards .card:hover {
  flex-grow: 4;
}

.services .services-cards a.card h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--background-color);
  transition: 0.3s;
  font-size: 20px;
}

.services .services-cards .card:hover h1 {
  top: 10px;
  left: 15px;
  transform: translate(0, 0);
}

.services .services-cards .card ol {
  font-size: 15px;
  position: absolute;
  color: var(--second-background-color);
  padding: 0 10px;
  margin-left: 20px;
  transform: translateY(100%);
  transition: 0.3s transform, 0.7s opacity;
  opacity: 0;
}

.services .services-cards .card:hover ol.main-body {
  transform: translateY(0);
  opacity: 1;
}

@media (max-width: 900px) {
  .services .services-cards {
    flex-direction: column;
  }
  .services .services-cards .card {
    width: 100%;
  }
}