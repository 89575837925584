header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 50px;
  border-bottom: 1px solid var(--text-color);
  backdrop-filter: blur(10px);
  z-index: 999;
  background: #eeeeee81;
}

header,
header > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
}

header .nav-button {
  display: none;
}

header img[alt="logo"] {
  height: 50px;
}

header .nav-link {
  position: relative;
  font-size: 14px;
  color: var(--text-color);
  text-decoration: none;
}

header .nav-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: var(--secondary-color);
  z-index: -1;
  transition: 0.3s;
}

header .nav-link:hover::before {
  height: 50%;
}

header .nav-button {
  padding: 7px;
  cursor: pointer;
  border-radius: 10px;
  background: none;
  border: 2px solid var(--text-color);
  color: var(--text-color);
  transition: 0.3s;
}
header .nav-button:hover {
  color: var(--secondary-color);
  background: var(--text-color);
}

@media (max-width: 900px) {
  header,
  header div.right-part {
    flex-direction: column;
    align-items: baseline;
  }

  header .left-part {
    width: 100%;
    justify-content: space-between;
  }

  header .right-part {
    align-items: baseline;
  }
  header .right-part[data-open="false"] {
    display: none;
  }

  header .nav-button {
    display: grid;
    place-content: center;
  }
}
