.preloader {
  position: fixed;
  inset: 0;
  z-index: 99999;
  cursor: wait;
}

.preloader * {
  pointer-events: none;
}

.inner {
  background: var(--preloader-background);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  transition: 0;
}

.inner h1 {
  font-size: 20px;
  line-height: 1em;
  color: var(--pColor);
}

.curve path {
  fill: var(--preloader-background);
}
