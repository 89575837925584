.main {
  position: relative;
}

.main .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: calc(100vw / 10);
  color: var(--background-color);
  text-shadow: 0 0 15px black;
  z-index: 555;
}

.gallery {
  height: 175vh;
  background: #2d2d2d;
  position: relative;
  display: flex;
  gap: 2vw;
  padding: 2vw;
  box-sizing: border-box;
  overflow: hidden;
}
.gallery .column {
  position: relative;
  height: 100%;
  width: 25%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 2vw;
}
.gallery .column .imageContainer {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 1vw;
  overflow: hidden;
}
.gallery .column .imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery .column:nth-of-type(1) {
  top: -45%;
}
.gallery .column:nth-of-type(2) {
  top: -95%;
}
.gallery .column:nth-of-type(3) {
  top: -45%;
}
.gallery .column:nth-of-type(4) {
  top: -75%;
}
