footer .builging-shape {
  height: 50px;
  background-image: url(../assets/imgs/buildings.svg);
  background-position: left;
  background-repeat: repeat-x;
  background-size: contain;
  transform: translateY(5px);
}
footer .footer-body {
  background: var(--text-color);
  padding: 50px calc(20px + 5vw) 20px;
}
footer .footer-body .footer-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  gap: 20px;
}

footer .footer-body .footer-top .footer-top-left div.company-name h2 {
  color: var(--background-color);
  font-size: 50px;
  line-height: 40px;
}

footer .footer-body .footer-top .footer-top-left div.company-name p {
  color: var(--second-background-color);
  font-size: 15px;
  line-height: 15px;
}

footer .footer-body .footer-top .footer-top-left div.company-links {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

footer .footer-body .footer-top .footer-top-left div.company-links a {
  text-decoration: none;
}

footer .footer-body .footer-top .footer-top-left div.company-links a span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  color: var(--second-background-color);
}

footer
  .footer-body
  .footer-top
  .footer-top-left
  div.company-links:has(a:hover)
  :not(a:hover)
  span {
  opacity: 0.2;
}

footer
  .footer-body
  .footer-top
  .footer-top-left
  div.company-links
  a:hover
  span {
  text-decoration: underline;
}

footer .footer-body .footer-top .footer-top-right iframe {
  width: calc(300px + 10vw);
  aspect-ratio: 1.6;
  border: none;
  border-radius: 15px;
  outline: none;
}

footer .footer-credit {
  margin-top: 15px;
}
footer .footer-credit p {
  width: 100%;
  text-align: center;
  font-size: 15px;
  color: var(--background-color);
}

@media (max-width: 900px) {
  footer .footer-body .footer-top {
    flex-direction: column;
  }

  footer .footer-body .footer-top .footer-top-right {
    align-self: center;
    width: 100%;
  }

  footer .footer-body .footer-top .footer-top-right iframe {
    width: 100%;
    max-height: 230px;
  }
}
