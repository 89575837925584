.background {
  position: fixed;
  inset: 0;
  background-image: url(../assets/imgs/square.svg);
  background-size: calc(20px + 0.7vw);
  background-repeat: repeat;
  -webkit-mask-image: linear-gradient(to bottom, white, transparent);
  mask-image: linear-gradient(to bottom, white, transparent);
  z-index: -1;
}

.background::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-50%, 0);
  background: var(--secondary-color);
  height: 250px;
  width: 250px;
  border-radius: 50%;
  opacity: 0.7;

  filter: blur(20px);
  z-index: -1;
}
.background::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: var(--secondary-color);
  height: 400px;
  width: 400px;
  border-radius: 50%;
  opacity: 0.4;
  filter: blur(30px);
  z-index: -1;
}
